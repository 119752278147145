<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-form-model-item label="id" prop="(id">
                <a-input v-model="rowData.id" placeholder="id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="用户id" prop="(userId">
                <a-input v-model="rowData.userId" placeholder="用户id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="省份id" prop="(provinceId">
                <a-input v-model="rowData.provinceId" placeholder="省份id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="省份" prop="(province">
                <a-input v-model="rowData.province" placeholder="省份"></a-input>
            </a-form-model-item>
            <a-form-model-item label="城市id" prop="(cityId">
                <a-input v-model="rowData.cityId" placeholder="城市id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="城市" prop="(city">
                <a-input v-model="rowData.city" placeholder="城市"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区县id" prop="(countyId">
                <a-input v-model="rowData.countyId" placeholder="区县id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区县" prop="(county">
                <a-input v-model="rowData.county" placeholder="区县"></a-input>
            </a-form-model-item>
            <a-form-model-item label="详细地址" prop="(address">
                <a-input v-model="rowData.address" placeholder="详细地址"></a-input>
            </a-form-model-item>
            <a-form-model-item label="地址类型（现居地址，身份证 地址等）" prop="(addrType">
                <a-input v-model="rowData.addrType" placeholder="地址类型（现居地址，身份证 地址等）"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建人" prop="(createUser">
                <a-input v-model="rowData.createUser" placeholder="创建人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建人id" prop="(createUserId">
                <a-input v-model="rowData.createUserId" placeholder="创建人id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建时间" prop="(createTime">
                <a-input v-model="rowData.createTime" placeholder="创建时间"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改人" prop="(modifyUser">
                <a-input v-model="rowData.modifyUser" placeholder="修改人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改人id" prop="(modifyUserId">
                <a-input v-model="rowData.modifyUserId" placeholder="修改人id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改时间" prop="(modifyTime">
                <a-input v-model="rowData.modifyTime" placeholder="修改时间"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否删除" prop="(flagDel">
                <a-input v-model="rowData.flagDel" placeholder="是否删除"></a-input>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="(remark">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
            <a-form-model-item label="版本号" prop="(version">
                <a-input v-model="rowData.version" placeholder="版本号"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editFirmManagerAddress, selectByIdFirmManagerAddress, addFirmManagerAddress } from '../api/FirmManagerAddressApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                id: [
                    { required: true, message: '请输入id', trigger: 'blur' }
                ],
                userId: [
                    { required: true, message: '请输入用户id', trigger: 'blur' }
                ],
                provinceId: [
                    { required: true, message: '请输入省份id', trigger: 'blur' }
                ],
                province: [
                    { required: true, message: '请输入省份', trigger: 'blur' }
                ],
                cityId: [
                    { required: true, message: '请输入城市id', trigger: 'blur' }
                ],
                city: [
                    { required: true, message: '请输入城市', trigger: 'blur' }
                ],
                countyId: [
                    { required: true, message: '请输入区县id', trigger: 'blur' }
                ],
                county: [
                    { required: true, message: '请输入区县', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
                addrType: [
                    { required: true, message: '请输入地址类型（现居地址，身份证 地址等）', trigger: 'blur' }
                ],
                createUser: [
                    { required: true, message: '请输入创建人', trigger: 'blur' }
                ],
                createUserId: [
                    { required: true, message: '请输入创建人id', trigger: 'blur' }
                ],
                createTime: [
                    { required: true, message: '请输入创建时间', trigger: 'blur' }
                ],
                modifyUser: [
                    { required: true, message: '请输入修改人', trigger: 'blur' }
                ],
                modifyUserId: [
                    { required: true, message: '请输入修改人id', trigger: 'blur' }
                ],
                modifyTime: [
                    { required: true, message: '请输入修改时间', trigger: 'blur' }
                ],
                flagDel: [
                    { required: true, message: '请输入是否删除', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
                version: [
                    { required: true, message: '请输入版本号', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addFirmManagerAddress(this.rowData) : await editFirmManagerAddress(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
